import { DOCUMENT_TITLE_DEFAULT } from "core/consts";
import { useEffect } from "react";
import { useTranslations } from "translations";
import Translations from "translations/types";

type PageMetaData = {
  description?: (translations: Translations) => string;
  title?: (translations: Translations) => string;
};

const usePageMetaData = ({
  description = () => "",
  title = () => "",
}: PageMetaData) => {
  const translations = useTranslations();
  useEffect(() => {
    document.title = title(translations);
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", description(translations));
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("name", "description");
      newMetaTag.setAttribute("content", description(translations));
      document.head.appendChild(newMetaTag);
    }

    return () => {
      document.title = DOCUMENT_TITLE_DEFAULT;
      if (metaDescription) {
        metaDescription.setAttribute("content", "");
      }
    };
  }, [title, description]);
};

export default usePageMetaData;
